<template>
  <div class="ok-lead">
    <div class="loader-wrap" v-show="loading">
      <div class="loader"></div>
    </div>
    <slideshow v-show="!loading" />
    <ok-content v-show="!loading" :loading.sync="loading" />
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    slideshow: () => import('@/components/Slideshow.vue'),
    'ok-content': () => import('@/components/Content.vue')
  },
  data: () => ({
    title: 'Premium Architecture & Design',
    loading: false
  }),
  metaInfo () {
    return {
      title: this.title
    }
  }
}
</script>

<style lang="scss">
.ok-lead {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
